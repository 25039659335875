import * as React from "react";
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout'
import "../styles/screens/Contact.scss";
import svg from '../assets/contact.svg';
import address from '../assets/icons/adress.svg';
import email from '../assets/icons/email.svg';
import phone from '../assets/icons/phone.svg';
import Form from "../components/Form";
import '../styles/screens/Contact.scss';

const Contact = () => {
  return (
    <Layout>
      <Helmet>
        <title>Веб студия в Тирасполе</title>
      </Helmet>
      <section className="main container d-flex flex-column flex-sm-row justify-content-center justify-content-md-around py-5 my-0 my-sm-5">
        <div className="col-xs-12 col-xl-7 animated fadeInUp slow">
          <img src={svg} alt="popple meteeng" className="w-100 img-response"/>
        </div>
        <div className="col-xs-12 col-xl-5 justify-content-end d-flex animated fadeIn slow">
          <Form />
        </div>
      </section>
      <section className="address container d-flex flex-column flex-sm-row justify-content-between text-center text-sm-left py-5 my-0 my-sm-5">
        <div className="pl-0 pl-sm-4 address_item animated fadeInUp slow delay-1s">
          <img src={address} alt="popple meteeng"/>
          <h5>Адрес</h5>
          <p>Приднестровье, Тирасполь<br/>ул. 25 Октября 1, 3300</p>
        </div>
        <div className="address_item animated fadeInUp slow delay-2s">
          <img src={email} alt="popple meteeng"/>
          <h5>Email</h5>
          <p><a href="mailto:sales@medev.com">support@ArtCode.studio</a></p>
        </div>
        <div className="pl-0 pr-sm-4 address_item animated fadeInUp slow delay-3s">
          <img src={phone} alt="popple meteeng"/>
          <h5>Телефон</h5>
          <p><a href="tel:+373(779)10057">+(373) 779 100 57</a></p>
        </div>
      </section>
    </Layout>
  );
};

export default Contact;