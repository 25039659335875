import * as React from "react";
// import axios from 'axios';
// import { BASE_URL } from '../api/baseUrl';
// import { useMachine } from '@xstate/react';
// import { Machine } from 'xstate'

const Form: React.FC = () => {

  const [name, setName] = React.useState<string>('');
  const [phone, setPhone] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [desc, setDesc] = React.useState<string>('');
    
  const mySubmitHandler = (event: any) => {
    event.preventDefault();  

    // axios.post(`${BASE_URL}/constactsForm`,{name, phone, email, desc})
    //   .then(res => res.status === 200 ? alert(res.data.message) : alert(res.data) )
    //   .catch(err => err)

    // let chearState = () => {
      setName('')
      setPhone('')
      setEmail('')
      setDesc('')
    // }
    // chearState();
  }

  return (
    <div className="form">
      <h1 className="title">Связаться с нами</h1>
      <p className="small_content">Наша команда готова ответить на ваши вопросы</p>
      <form className="contact_form" onSubmit={mySubmitHandler}>
        <div className="form_group">
          <input
            type="name"
            name="name"
            className="form_control"
            placeholder="Имя"
            required
            aria-required="true"
            value={name}
            onChange={e => setName(e.target.value)}
          />
          {/* <label className="error" >This field is required.</label> */}
        </div>
        <div className="form_group">
          <input
            type="number"
            className="form_control"
            placeholder="Телефон"
            required
            aria-required="true"
            value={phone}
            onChange={e => setPhone(e.target.value)}
          />
        </div>
        <div className="form_group">
          <input 
            type="email"
            className="form_control"
            placeholder="Email"
            required
            aria-required="true"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </div>
        <div className="form_group">
          <textarea
            className="form_control"
            placeholder="Область описания"
            required
            aria-required="true"
            value={desc}
            onChange={e => setDesc(e.target.value)}
          />
        </div>
        <button type="submit" className="formBtn">Отправить</button>
      </form>
    </div>
  );
};

export default Form;